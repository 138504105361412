import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextField, Divider, Typography, Box } from '@mui/material'
import AreaCodeSelector from './AreaCodeSelector';
import axios from 'axios';
import { URL } from '../api/apiConstants';
import { ConnectWallet } from '../hooks/WalletHooks';

export default function ResponsiveModal({ open, setOpen, accoutCompleted, accountData }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [name, setName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [number, setNumber] = React.useState('')
    const [area, setArea] = React.useState(null)
    const [isDisabled, setIsDisabled] = React.useState(false)
    const [active, account] = ConnectWallet()


    React.useEffect(() => {
        isSubmitable()
    }, [name, lastName, email, number, area])

    const handleChangeNumber = ({ target: { value } }) => {
        const regex = new RegExp(/^\d*$|(?=^.{0,}$)^\d+\.\d{0,2}$/, 'gm')
        if (regex.test(value)) setNumber(value)
        // if (value.length < 4) setNumberError(true)
        // else setNumberError(false)
    }

    const isSubmitable = () => {
        if (name !== '' && lastName !== '' && email !== '' && number !== '' && area !== null) setIsDisabled(false)
        else setIsDisabled(true)
    }

    const handleSubmitUserData = () => {
        axios.post(`${URL}api/v1/client/new`,
            {
                wallet: account,
                name,
                lastName,
                email,
                phone: area.dialCode + number
            })
            .then(res => console.log(res))
            .catch(err => console.log(err))
            .finally(() => setOpen(false))
    }

    const handleClose = () => {
        setOpen(false);
    };

    return <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
    >
        <DialogTitle id="responsive-dialog-title">
            Complete your account data
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Fill this data to use Royal South marketplace
            </DialogContentText>
            <Divider color='#dcdcdc' variant='middle' sx={{ my: 3 }} />
            <TextField
                disabled={true}
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="filled"
                value={name || accountData?.name}
                onChange={(e) => setName(e.target.value)}
            />
            <TextField
                disabled={accoutCompleted}
                autoFocus
                margin="dense"
                id="last name"
                label="Last Name"
                type="text"
                fullWidth
                variant="filled"
                value={lastName || accountData?.lastName}
                onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
                disabled={accoutCompleted}
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="filled"
                value={email || accountData?.email}
                onChange={(e) => setEmail(e.target.value)}
            />

            {!accoutCompleted && <Box sx={{ display: 'flex', alignItems: 'center', width: "100%", gap: 1, mt: '8px', mb: '4px' }}>
                {/* <Phone /> */}
                <AreaCodeSelector value={area} setValue={setArea} disabled={accoutCompleted} />
                <TextField
                    disabled={accoutCompleted}
                    sx={{ flex: 6.3 }}
                    label={'phone'}
                    value={number || accountData?.phone}
                    placeholder="Number"
                    variant="filled"
                    // onKeyUp={handleKeyDown}
                    onChange={handleChangeNumber}
                    InputProps={{
                        autoComplete: 'off'
                    }}
                // error={numberError}
                // helperText={numberError && 'Phone number must be at least 4 characters long'}
                />
            </Box>}

        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleClose}>
                <Typography color={'primary'}>
                    Cancel
                </Typography>
            </Button>
            {!accoutCompleted && <Button onClick={handleSubmitUserData} autoFocus disabled={isDisabled}>
                <Typography color={'primary'}>
                    Submit
                </Typography>
            </Button>}
        </DialogActions>
    </Dialog>
}