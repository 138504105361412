import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import data from './areaCodes';
import { Box, Typography } from '@mui/material';

const filter = createFilterOptions();

export default function AreaCodeSelector({ value, setValue, disabled }) {

    return (
        <Autocomplete
            disabled={disabled}
            value={value}
            onChange={(event, newValue, reason) => {
                if (typeof newValue === 'string') {
                    setValue({
                        title: newValue.dialCode,
                    });
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                        title: newValue.inputValue,
                    });
                } else {
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                let filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.isoCode);

                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                    });
                }
                console.log(filtered)
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={data}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option.isoCode === 'string') {
                    return option.isoCode;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.dialCode;
            }}
            renderOption={(props, option) => <Box {...props} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><Typography fontFamily='Antonio'>{option.isoCode}</Typography><img width={25} height={20} src={option.flag} /><Typography fontFamily='Antonio'>{option.dialCode}</Typography></Box>}
            freeSolo
            sx={{ flex: 3.7 }}
            renderInput={(params) => (
                <TextField {...params} variant='filled' label={"Area code"} />
            )}
        />
    );
}

