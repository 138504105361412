import React from 'react'

const useSupportTouch = () => {
    const [supportTouch, setSupportTouch] = React.useState(false)
    React.useEffect(() => {
        if ((!supportTouch && 'ontouchstart' in window) || navigator.msMaxTouchPoints) setSupportTouch('ontouchstart' in window || navigator.msMaxTouchPoints)

    })
    return supportTouch
}

export default useSupportTouch