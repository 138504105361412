import { useWeb3React } from '@web3-react/core';
import { useState, useEffect } from 'react'
import { CheckSession } from '../api/User';

export function useAuth() {
    const [auth, setAuth] = useState(false)
    const { active } = useWeb3React();
    // const expires = new Date(token?.expire).getMilliseconds()
    // const now = new Date().getMilliseconds()

    const checkOnPageLoad = async (jwt) => {
        const { data: { available, token } } = await CheckSession(jwt)
        if (!available) {
            localStorage.setItem('token', null)
        }
        if (available) {
            if (token && active) setAuth(true)
            if (!token || !active) setAuth(false)
        }
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'))?.token
        if (!auth) checkOnPageLoad(token)

        // const interval = setInterval(Revalidate, 2000)
        // return () => clearInterval(interval)
    }, [active]) // intentionally only running on mount (make sure it's only mounted once :))

    // if the connection worked, wait until we get confirmation of that to flip the flag

    return [auth, setAuth]
}