import axios from "axios"
import { URL, LogRoute, sessionCheckRoute, mynfts, isAdmin } from "./apiConstants"

const LogUser = async (user, password) => {
    try {
        const res = await axios.post(`${URL}${LogRoute}`, { user: user, password: password }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return res
    } catch (e) {
        return { error: e, failed: true }
    }
}

const CheckSession = async (token) => {
    try {
        const res = await axios.post(`${URL}${sessionCheckRoute}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
        })
        return res
    } catch (e) {
        return { data: { available: false }, failed: true }
    }
}

const getNFTs = async (account) => {
    try {
        const res = await axios.get(`${URL}${mynfts}${account}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return res
    } catch (e) {
        return { data: { available: false }, failed: true }
    }
}

const getIsAdmin = async (account) => {
    try {
        const res = await axios.get(`${URL}${isAdmin}${account}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return res
    } catch (e) {
        return { data: e, failed: true }
    }
}

export {
    LogUser,
    CheckSession,
    getNFTs,
    getIsAdmin
}