import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import {
    Drawer as MuiDrawer,
    AppBar as MuiAppBar,
    Box,
    Toolbar,
    List,
    CssBaseline,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Badge
} from '@mui/material/';

import {
    Logout,
    AdminPanelSettings,
    Dashboard,
    MapsHomeWork,
    AddCircle,
    Menu,
    ChevronLeft,
    ChevronRight,
    Grade,
    Category,
    Inventory,
    CurrencyExchange,
    FactCheckRounded,
    Person
} from '@mui/icons-material';

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "./assets/img/navLogo.png"
import { ConnectWallet, DisconnectWallet, useEagerConnect } from './hooks/WalletHooks';
import { Button } from '@mui/material';
import truncateWallet from './helpers/truncateWallet';
import { ToastContainer } from 'react-toastify';
import { useAuth } from './hooks/Auth';
import { CheckSession, getIsAdmin } from './api/User';
import useSupportTouch from './hooks/SupportTouch';
import ResponsiveModal from './components/ResponsiveModal';
import { URL } from './api/apiConstants';
import axios from 'axios';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(0)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function MiniDrawer() {
    const theme = useTheme();
    const supportTouch = useSupportTouch()
    const [open, setOpen] = React.useState(null);
    const navigate = useNavigate()
    const [active, account, connect] = ConnectWallet()
    useEagerConnect()
    const location = useLocation();
    const [auth, setAuth] = useAuth()
    const [disconnect] = DisconnectWallet(setAuth)
    const [accoutCompleted, setAccoutCompleted] = React.useState(false)
    const [openModal, setOpenModal] = React.useState(false)
    const [accountData, setAccountData] = React.useState(null)

    const listEl = [
        {
            name: "Dashboard",
            icon: <Dashboard />,
            nav: () => navigate("/dashboard"),
            location: "/dashboard"
        },
        {
            name: "Projects",
            icon: <MapsHomeWork />,
            nav: () => navigate("/"),
            location: "/"
        },
        {
            name: "Rewards",
            icon: <Grade />,
            nav: () => navigate('/rewards'),
            location: "/rewards"
        },
        {
            name: "Inventory",
            icon: <Category />, //TODO: este icono necesita ayuda
            nav: () => navigate('/mynfts'),
            location: "/mynfts"
        },
        {
            name: "Admin Panel",
            icon: <AdminPanelSettings />,
            nav: () => navigate("/login"),
            location: "/login"
        },
        {
            name: "Create New",
            icon: <AddCircle />,
            nav: () => navigate('/new'),
            location: "/new",
            admin: true
        },
        {
            name: "Archived",
            icon: <Inventory />,
            nav: () => navigate('/archived'),
            location: "/archived",
            admin: true
        },
        {
            name: "Whitelist",
            icon: <FactCheckRounded />,
            nav: () => navigate('/whitelist'),
            location: "/whitelist",
            admin: true
        },
        // {
        //     name: "Refunds",
        //     icon: <CurrencyExchange />,
        //     nav: () => navigate('/refunds'),
        //     location: "/refunds",
        //     admin: true
        // }
    ]

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const Revalidate = async () => {
        // console.log('revalidate', auth)
        if (auth) {
            const token = JSON.parse(localStorage.getItem('token'))
            const res = await CheckSession(token?.token)
            if (res?.data?.available) localStorage.setItem('token', JSON.stringify(res.data))
            if (!res?.data?.available) {
                localStorage.setItem('token', null)
                setAuth(false)
                navigate('/')
            }

        }
    }


    const checkUserCompleted = async () => {
        axios.get(`${URL}api/v1/client/data/${account}`).then(res => {
            console.log(res)
            if (res.data.exists) { setAccoutCompleted(true); setAccountData(res.data) }
            else setAccoutCompleted(false)
        }).catch(err => console.log(err))
    }

    // const fetchIsAdmin = async () => {
    //     const res = await getIsAdmin(account ? account.toLowerCase() : '')
    //     if (res && !res.failed) {
    //         setIsAdmin(res.data.auth)
    //     }else {
    //         console.log(!res.failed)
    //         setIsAdmin(!res.failed)
    //     }
    // }

    React.useEffect(() => {
        checkUserCompleted()
        const interval = setInterval(Revalidate, 30000)
        setTimeout(() => {
            if (supportTouch && open === null) setOpen(false)
            if (!supportTouch && open === null) setOpen(true)
        }, 1000)
        // fetchIsAdmin()
        return () => clearInterval(interval)
    })

    return (<>
        <Box sx={{ display: 'flex', overflow: "hidden" }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} color="background">
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex" }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 2,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <Menu />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            onClick={handleDrawerClose}
                            sx={{
                                marginRight: 2,
                                ...(!open && { display: 'none' }),
                            }}>
                            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                        <img src={Logo} onClick={() => navigate('/')} style={{ cursor: "pointer" }} width={190} />
                    </Box>

                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            sx={{ color: 'secondary.main' }}
                            onClick={() => active ? disconnect() : connect()}
                        >
                            {active ?
                                <>
                                    {truncateWallet(account)}
                                    <Logout sx={{ ml: 1 }} />
                                </>
                                :
                                "connect wallet"
                            }</Button>

                        {active &&
                            <>
                                <Divider color='#dcdcdc' flexItem orientation='vertical' variant='middle' />

                                <IconButton color='primary' onClick={() => setOpenModal(true)}>
                                    {!accoutCompleted ?
                                        <Badge color="secondary" variant='dot'>
                                            <Person />
                                        </Badge>
                                        :
                                        <Person />
                                    }
                                </IconButton>
                            </>
                        }
                    </Box>

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {listEl.map((item, index) => {
                        if ((account !== '0x0bb14aDFc5ED1e126592042FDFED913F4d5E71f0' && item.name === 'Admin Panel')) return null
                        // if ((account !== '0x0bb14aDFc5ED1e126592042FDFED913F4d5E71f0' && item.name === 'Admin Panel')) return null
                        // if ((!isAdmin && item.name === 'Admin Panel') || (!auth && item.name === "Create New") || (!auth && item.name === "Archived")) return null
                        if (!auth && item.admin) return null
                        if (item.name === 'Admin Panel' && auth) return null
                        return <ListItem key={item.name} disablePadding sx={{ display: 'block' }} onClick={item.nav}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                selected={item.location === location.pathname}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: "text.hint"
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    })}
                </List>
            </Drawer>
            <Box component="main" sx={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
                <Outlet context={[auth, setAuth, accoutCompleted]} />
            </Box>
        </Box>
        <ResponsiveModal open={openModal} setOpen={setOpenModal} accoutCompleted={accoutCompleted} accountData={accountData}/>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='dark'
            style={{ position: 'fixed', zIndex: 200 }}
        />
    </>
    );
}
