// const devBaseURL = 'http://172.26.56.163:4022/'
const devBaseURL = 'http://192.168.1.151:4022/'
const prodBaseURL = 'https://api.royalsouthestate.com/'

const URL = process.env.NODE_ENV === 'development' ? devBaseURL : prodBaseURL

//USER && AUTH ROUTES
const LogRoute = 'api/v1/user/auth/login'
const sessionCheckRoute = 'api/v1/user/auth/check'
const isAdmin = 'api/v1/user/display/login/'
const mynfts = 'api/v1/nft/mynft/'
/////////////

//ADMIN ROUTES
const pruebas = 'api/v1/nft/project/new'
const pruebas2 = 'api/v1/nft/project/'
//////////////

//////////// PROJECT RELATED ROUTES ////////////
const allProjects = 'api/v1/nft/all?skip='
const project = 'api/v1/nft/project/'
const draft = 'api/v1/nft/project/draft/'
const archived = 'api/v1/nft/archived?skip='
const refundNfts = 'api/v1/nft/declined/tokens/'
//////////// PROJECT RELATED ROUTES ////////////

///////// REWARD RELATED ROUTES ///////////
const projectForReward = 'api/v1/nft/rewards/all'
const rewards = 'api/v1/nft/rewards/myrewards'
const rewardRelatedData = 'api/v1/nft/reward/percentage'
const allRewards = 'api/v1/nft/rewards/historical'
const saveReward = 'api/v1/nft/rewards/new'
const rewardTokens = 'api/v1/nft/reward/tokens?user='
const getRewardCount = 'api/v1/nft/graphics/reward/count'
///////// REWARD RELATED ROUTES ///////////

////////// CHART RELATED DATA //////////
const getProjectCountChart = 'api/v1/nft/graphics/tokens/project'
const PieChartData = 'api/v1/nft/graphics/approved'
const usersPerProjects = 'api/v1/nft/graphics/tokens/user'
////////// CHART RELATED DATA //////////

//////////////// WHITELIST RELATED ROUTES //////////////
const whitelist = 'api/v1/nft/whiteList/auth?user='
const whitelistProjects = 'api/v1/nft/whiteList/projects'
const whitelistList = 'api/v1/nft/whiteList/list'
const whitelistUsers = 'api/v1/nft/whiteList/users/'
//////////////// WHITELIST RELATED ROUTES //////////////

const pdf = 'api/v1/pdfApi/'

export {
    URL,
    LogRoute,
    sessionCheckRoute,
    pruebas,
    pruebas2,
    allProjects,
    project,
    draft,
    projectForReward,
    mynfts,
    rewards,
    rewardRelatedData,
    saveReward,
    archived,
    isAdmin,
    rewardTokens,
    getProjectCountChart,
    getRewardCount,
    PieChartData,
    usersPerProjects,
    whitelistProjects,
    whitelist,
    pdf,
    whitelistList,
    whitelistUsers,
    refundNfts,
    allRewards
}