
import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
    palette: {
        type: 'light',
        secondary: {
            main: '#ffb700',
            disabled: '#ffb70042',
        },
        primary: {
            main: '#989595',
            contrastText: 'rgba(189,189,189,0.87)',
        },
        text: {
            primary: '#ffffff',
            secondary: '#7F7F7F',
            disabled: '#ffebee',
            hint: '#dcdcdc',
        },
        background: {
            default: '#1a1a1a',
            paper: '#1c1e20',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1736,
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    // color: 'red',
                    //   backgroundColor: '#dcdcdc42'
                    '.MuiFilledInput-root': {
                        backgroundColor: '#d0d0d00f'
                    },
                    '.MuiFilledInput-root:after': {
                        borderBottom: '2px solid #ffb700'
                    }
                }
            }
        },
        MuiDatePicker: {
            styleOverrides: {
                root: {
                    // color: 'red',
                    //   backgroundColor: '#dcdcdc42'
                    '.MuiPickersArrowSwitcher-button': {
                        color: '#ffb700 !important'
                    },
                    '.MuiButtonBase-root .MuiIconButton-root':{
                        color: '#7F7F7F'
                    }
                }
            }
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    ":disabled":{
                        backgroundColor: '#7F7F7F'
                    },
                    color: '#1a1a1a'
                }
            }
        }
    }
});

export default theme;