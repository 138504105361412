import './polyfill'
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import 'react-toastify/dist/ReactToastify.min.css';
import { Web3Provider } from "@ethersproject/providers";

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

const LazyHome = lazy(() => import("../src/views/Home"));
const LazyLogin = lazy(() => import("../src/views/Login"));
const LazyDashboard = lazy(() => import("../src/views/Dashboard"));
const LazyNftDetails = lazy(() => import("../src/views/NftDetails"));
const LazyNewProject = lazy(() => import("../src/views/NewProject"));
const LazyRewards = lazy(() => import("../src/views/Rewards"));
const LazyMyNfts = lazy(() => import("../src/views/MyNfts"));
const LazyArchived = lazy(() => import("../src/views/Archived"));
const LazyRefunds = lazy(() => import("../src/views/Refunds"));
const LazyWhitelist = lazy(() => import("../src/views/Whitelist"));
const LazyNotFound = lazy(() => import("../src/views/NotFound"));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<Suspense><LazyHome /></Suspense>} />
              <Route path='/dashboard' element={<Suspense><LazyDashboard /></Suspense>} />
              <Route path='/login' element={<Suspense><LazyLogin /></Suspense>} />
              <Route path="/new" element={<Suspense><LazyNewProject /></Suspense>} />
              <Route path="/nft/:nftID" element={<Suspense><LazyNftDetails /></Suspense>} />
              <Route path="/rewards" element={<Suspense><LazyRewards /></Suspense>} />
              <Route path="/mynfts" element={<Suspense><LazyMyNfts /></Suspense>} />
              <Route path="/archived" element={<Suspense><LazyArchived /></Suspense>} />
              <Route path="/refunds" element={<Suspense><LazyRefunds /></Suspense>} />
              <Route path="/whitelist" element={<Suspense><LazyWhitelist /></Suspense>} />
              <Route path="*" element={<Suspense><LazyNotFound /></Suspense>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  </Web3ReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
